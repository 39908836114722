import Head from 'next/head'
import { useEffect } from 'react'
import favicons from '../favicons'

export function Favicon() {
	useEffect(() => {
		let i = 0
		const timeoutDuration = 500
		let timer: ReturnType<typeof setTimeout>

		const loop = () => {
			const faviconElement = document.getElementById('shortcutIcon')
			if (faviconElement) {
				i++
				faviconElement.setAttribute('type', 'image/png')
				faviconElement.setAttribute('href', favicons[i % favicons.length].src)
			}
			timer = setTimeout(loop, timeoutDuration)
		}
		loop()

		return () => {
			clearTimeout(timer)
		}
	}, [])

	return (
		<Head>
			<link id="shortcutIcon" rel="shortcut icon" href="/favicon.svg" type="image/svg+xml" />
		</Head>
	)
}
