const favicons = [
	{ src: 'https://mgwdata.net/heyfomo/favicons/favicon-1.png' },
	{ src: 'https://mgwdata.net/heyfomo/favicons/favicon-2.png' },
	{ src: 'https://mgwdata.net/heyfomo/favicons/favicon-3.png' },
	{ src: 'https://mgwdata.net/heyfomo/favicons/favicon-4.png' },
	{ src: 'https://mgwdata.net/heyfomo/favicons/favicon-5.png' },
	{ src: 'https://mgwdata.net/heyfomo/favicons/favicon-6.png' },
	{ src: 'https://mgwdata.net/heyfomo/favicons/favicon-7.png' },
	{ src: 'https://mgwdata.net/heyfomo/favicons/favicon-8.png' },
	{ src: 'https://mgwdata.net/heyfomo/favicons/favicon-9.png' },
	{ src: 'https://mgwdata.net/heyfomo/favicons/favicon-10.png' },
	{ src: 'https://mgwdata.net/heyfomo/favicons/favicon-11.png' },
	{ src: 'https://mgwdata.net/heyfomo/favicons/favicon-12.png' },
	{ src: 'https://mgwdata.net/heyfomo/favicons/favicon-13.png' },
	{ src: 'https://mgwdata.net/heyfomo/favicons/favicon-14.png' },
	{ src: 'https://mgwdata.net/heyfomo/favicons/favicon-15.png' },
	{ src: 'https://mgwdata.net/heyfomo/favicons/favicon-16.png' },
	{ src: 'https://mgwdata.net/heyfomo/favicons/favicon-17.png' },
	{ src: 'https://mgwdata.net/heyfomo/favicons/favicon-18.png' },
	{ src: 'https://mgwdata.net/heyfomo/favicons/favicon-19.png' },
]
export default favicons
