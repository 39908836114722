import type { ImageLoader, ImageProps } from 'next/image'
import NextImage from 'next/image'
import React from 'react'

/*
Zatím nejde použít protože Next neumí podporovat svůj výchozí image loader a kombinovat ho s vlastním
*/

const s3BaseUrl = 'https://s3.eu-central-1.amazonaws.com/'

const betaCdn = 'https://api-contember-mgw-cz.imgix.net/'
const prodCdn = 'https://api-contember-mangoweb-cz.imgix.net/'

const storageUrlFragmentBeta = 'api.contember.mgw.cz/'
const storageUrlFragment = 'api.contember.mangoweb.cz/'

const s3BetaUrl = s3BaseUrl + storageUrlFragmentBeta
const s3ProdUrl = s3BaseUrl + storageUrlFragment

function normalizeSrc(src: string) {
	return src[0] === '/' ? src.slice(1) : src
}

const createImgixLoader =
	(root: string, prefix: string): ImageLoader =>
	({ src, width, quality }) => {
		// Demo: https://static.imgix.net/daisy.png?format=auto&fit=max&w=300
		const params = ['auto=format', 'fit=max', 'w=' + width]
		let paramsString = ''
		if (quality) {
			params.push('q=' + quality)
		}
		if (params.length) {
			paramsString = '?' + params.join('&')
		}
		return `${root}${normalizeSrc(src.replace(prefix, ''))}${paramsString}`
	}

const loaders = {
	beta: createImgixLoader(betaCdn, s3BetaUrl),
	prod: createImgixLoader(prodCdn, s3ProdUrl),
}

export function cdnUrl(url: string) {
	return url.replace(s3BetaUrl, betaCdn).replace(s3ProdUrl, prodCdn)
}

export function ImgixImage(props: ImageProps) {
	const { src } = props
	const overrides = React.useMemo<Partial<ImageProps>>(() => {
		if (typeof src === 'string') {
			if (src.indexOf(s3ProdUrl) === 0) {
				return {
					loader: loaders.prod,
				}
			}
			if (src.indexOf(s3BetaUrl) === 0) {
				return {
					loader: loaders.beta,
				}
			}
			return {
				unoptimized: true,
			}
		}
		return {}
	}, [src])

	const removeDimensionsForFillLayout =
		props.layout === 'fill' ? { width: undefined, height: undefined } : {}

	return <NextImage {...props} {...overrides} {...removeDimensionsForFillLayout} />
}
