import { z } from 'zod'

export const SignInInput = z.object({
	email: z
		.string()
		.email()
		.transform((email) => email.toLowerCase()),
	password: z.string().min(6),
})

export type SignInInput = z.infer<typeof SignInInput>
