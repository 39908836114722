import { z } from 'zod'

export const SignUpInput = z.object({
	email: z
		.string()
		.email()
		.transform((email) => email.toLowerCase()),
	password: z.string().min(6),
})

export type SignUpInput = z.infer<typeof SignUpInput>
