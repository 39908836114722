import React from 'react'
import { ImgixImage } from './ImgixImage'

export const Image: typeof ImgixImage = (props) => {
	const { src } = props

	const srcProps = React.useMemo(() => {
		if (typeof src === 'string') {
			const isSvg = /\.svg$/.test(src)
			if (!isSvg) {
				return { src }
			}
			const isGif = /\.gif$/.test(src)
			if (!isGif) {
				return { src }
			}
			return {
				src,
				unoptimized: true,
			}
		}
		return {
			src,
		}
	}, [src])

	const combined = { ...props, ...srcProps } as React.ComponentProps<typeof ImgixImage>

	if (typeof combined.src === 'string' && /\.svg$/.test(combined.src)) {
		if (!(combined.width ?? false) && !(combined.width ?? false)) {
			combined.width = 16
			combined.height = 16
		}
	}

	if (Boolean(process.env.NEXT_PUBLIC_UNOPTIMIZE_IMAGES)) {
		combined.unoptimized = true
	}
	return <ImgixImage {...combined} />
}
