import { useMutation } from 'react-query'
import { SignInInput } from './SignInInput'
import { useAuth } from './UserContext'
import style from './SigninForm.module.sass'
import { Button } from '../components/Button'
import { Link } from '../components/Link'

export function SignInForm(props: { toggleRegisterFormCallback?: () => void }) {
	const auth = useAuth()

	const mutation = useMutation(async (formData: FormData) => {
		const data = Object.fromEntries([...formData.entries()])

		const validData = SignInInput.parse(data)

		const signIn = await auth.signIn(validData)

		return signIn
	})

	return (
		<form
			action=""
			method="POST"
			onSubmit={async (e) => {
				e.preventDefault()
				mutation.mutate(new FormData(e.currentTarget))
			}}
			className={style.wrapper}>
			<fieldset className={style.fieldset} disabled={mutation.isLoading}>
				<input className={style.input} name="email" type="email" placeholder="Váš e-mail" />
				<input className={style.input} name="password" type="password" placeholder="Heslo" />
				<div className={style.actions}>
					<Link href="/reset-hesla">Reset hesla</Link>
					<button type="button" onClick={props.toggleRegisterFormCallback}>
						Registrace
					</button>
				</div>
				<div>
					<Button view="highlight" type="submit">
						Přihlásit se
					</Button>
				</div>
			</fieldset>
			<div className={style.error}>
				{mutation.isError ? 'Něco se nepovedlo, zkuste to prosím znovu.' : ''}
			</div>
		</form>
	)
}
