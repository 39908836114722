export function detectExternalUrl(url: string) {
	const firstChar = url.substr(0, 1)
	if (firstChar === '/' || firstChar === '#') {
		return false
	}
	if (
		url.indexOf('https://heyfomo.cz/') === 0 ||
		url.indexOf('https://www.heyfomo.cz/') === 0 ||
		url.indexOf(String(process.env.NEXT_PUBLIC_BASE_URL)) === 0
	) {
		return false
	}
	return true
}
