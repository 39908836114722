/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import clsx from 'clsx'
import NextLink from 'next/link'
import type { MutableRefObject } from 'react'
import React from 'react'
import type { LinkResult } from '../data/fragments/LinkFragment'
import type { UrlResult } from '../data/fragments/UrlFragment'
import { useIsActiveUrl } from '../hooks/useIsActiveUrl'
import { detectExternalUrl } from '../utils/detectExternalUrl'
import { getUrlFromLinkResult } from '../utils/getUrlFromLinkResult'

export type CommonLinkProps = {
	className?: string
	activeClassName?: string
	externalInNewTab?: boolean
	ariaLabel?: string
	target?: string
	onClick?: React.MouseEventHandler<HTMLAnchorElement>
	ref?: MutableRefObject<HTMLAnchorElement | null>
	dataAttributes?: {
		[name: string]: string
	}
}

export type HrefLinkProps = {
	href: string
	children?: React.ReactNode
}

export type ContemberLinkProps = {
	link: LinkResult
	children?: React.ReactNode
}

export type ContemberUrlProps = {
	url: UrlResult
	children?: React.ReactNode
}

export type LinkProps = CommonLinkProps & (HrefLinkProps | ContemberLinkProps | ContemberUrlProps)

export const Link = React.forwardRef<HTMLAnchorElement, LinkProps>((props, ref) => {
	const linkProps =
		'href' in props
			? { href: props.href }
			: 'url' in props
			? { href: props.url.url ?? '#' }
			: getUrlFromLinkResult(props.link)

	const isExternal = detectExternalUrl(linkProps.href)
	const isActive = useIsActiveUrl(linkProps.href)

	const label = props.children

	const target = isExternal ? '_blank' : undefined
	const rel = isExternal ? 'noreferrer' : undefined

	return (
		<NextLink {...linkProps}>
			<a
				aria-label={props.ariaLabel}
				className={clsx(props.className, isActive && props.activeClassName)}
				target={target}
				onClick={props.onClick}
				rel={rel}
				ref={ref}
				{...Object.fromEntries(
					Object.entries(props.dataAttributes ?? []).map(([name, value]) => [`data-${name}`, value])
				)}>
				{label}
			</a>
		</NextLink>
	)
})

Link.displayName = 'Link'
