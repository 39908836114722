import React from 'react'
import { createFetcher } from '../../libs/next/api/jsonApiHandler'
import type { API } from '../../pages/api/search/[handler]'

const api = createFetcher<API>('/api/search/[handler]')

export function useSearchContextRoot() {
	const [query, setQuery] = React.useState<string | null>()

	return React.useMemo(
		() => ({
			query: query,
			setQuery: setQuery,
			search: (query: string) => {
				console.log('search')
				return api('search', { query: query }).then(async (res) => {
					console.log(res)
					return res
				})
			},
		}),
		[query]
	)
}

export type SearchContextType = ReturnType<typeof useSearchContextRoot>

export const SearchContext = React.createContext<null | SearchContextType>(null)

export function SearchContextProvider(props: { children: React.ReactNode }) {
	const value = useSearchContextRoot()
	return <SearchContext.Provider value={value}>{props.children}</SearchContext.Provider>
}

export function useSearch() {
	const context = React.useContext(SearchContext)

	if (!context) {
		throw new Error('Missing SearchContext')
	}

	return context
}
