import React from 'react'
import { createFetcher } from '../../libs/next/api/jsonApiHandler'
import type { API } from '../../pages/api/auth/[handler]'
import type { ReaderResult } from '../data/fragments/ReaderFragment'
import type { AddCommentData } from './AddCommentData'
import type { LikeDiscussionPostData } from './LikeDiscussionPostData'
import type { SignInInput } from './SignInInput'
import type { SignUpInput } from './SignUpInput'
import type { UserSettingsUpdate } from './UserSettingsUpdate'
import type { UpdatePointsData } from './UpdatePointsData'

export type Auth =
	| { status: 'loading' }
	| { status: 'signedOut' }
	| { status: 'signedIn'; user: ReaderResult }

const api = createFetcher<API>('/api/auth/[handler]')

export function useAuthContextRoot() {
	const [auth, setAuth] = React.useState<Auth>({ status: 'loading' })
	const [modalOpen, setModalOpen] = React.useState<boolean>(false)

	const reloadUser = React.useCallback(async () => {
		const { me } = await api('me', {})

		if (me) {
			setAuth({ status: 'signedIn', user: me })
		} else {
			setAuth({ status: 'signedOut' })
		}
	}, [])

	React.useEffect(() => {
		reloadUser()
	}, [reloadUser])

	const toggleModal = React.useCallback(() => {
		setModalOpen((old: boolean) => !old)
	}, [])

	return React.useMemo(
		() => ({
			...auth,
			modalOpen: modalOpen,
			reloadUser: reloadUser,
			toggleModal: toggleModal,
			signIn: (data: SignInInput) =>
				api('signIn', data).then((res) => {
					if (res.user) {
						setAuth({ status: 'signedIn', user: res.user })
						toggleModal()
					} else {
						setAuth({ status: 'signedOut' })
					}
					return res
				}),
			signUp: (data: SignUpInput) =>
				api('signUp', data).then(async (res) => {
					await api('signIn', data).then((res) => {
						if (res.user) {
							setAuth({ status: 'signedIn', user: res.user })
							toggleModal()
						} else {
							setAuth({ status: 'signedOut' })
						}
						return res
					})
					return res
				}),
			signOut: () =>
				api('signOut', {}).then((res) => {
					setAuth({ status: 'signedOut' })
					const token = res.token
					window.location.replace(
						`https://login.cncenter.cz/connect/endsession?id_token_hint=${token}&post_logout_redirect_uri=https://heyfomo.cz/`
					)
					return res
				}),
			me: () =>
				api('me', {}).then(({ me }) => {
					if (me) {
						setAuth({ status: 'signedIn', user: me })
					} else {
						setAuth({ status: 'signedOut' })
					}
					return me
				}),
			addComment: (data: AddCommentData) => {
				return api('addComment', data).then(async (res) => {
					return res
				})
			},
			updatePoints: (data: UpdatePointsData) => {
				return api('updatePoints', data).then(async (res) => {
					return res
				})
			},
			getPointsTransactions: () => {
				return api('getPointsTransactions', {}).then(async (res) => {
					return res
				})
			},
			likePost: (data: LikeDiscussionPostData) => {
				return api('likePost', data).then(async (res) => {
					return res
				})
			},
			initSignInIDP: async () => {
				const initSignInIDP = await api('initSignInIDP', {}).then(async (res) => {
					return res
				})
				if (
					initSignInIDP.status === 'ok' &&
					initSignInIDP.signInIDP?.authUrl &&
					initSignInIDP.signInIDP.sessionData
				) {
					localStorage.setItem(
						'initSignInIDP-data',
						JSON.stringify(initSignInIDP.signInIDP.sessionData)
					)
					localStorage.setItem('signin-initial-url', window.location.href)
					window.location.replace(initSignInIDP.signInIDP.authUrl)
				}
			},
			signInIDP: (data: { sessionData: string; idpResponseUrl: string; redirectUrl: string }) => {
				return api('signInIDP', data).then(async (res) => {
					return res
				})
			},
			updateSettings: (data: UserSettingsUpdate) => {
				return api('updateSettings', data).then(async (res) => {
					console.log(res)
					if (res.uploadUrl) {
						const u = res.uploadUrl
						await fetch(u.url, {
							method: u.method,
							body: data.avatarFile,
							// eslint-disable-next-line @typescript-eslint/ban-ts-comment
							//@ts-ignore
							headers: u.headers.reduce((headers, h) => ({ ...headers, [h.key]: h.value }), {}),
						})
					}
					return res
				})
			},
		}),
		[auth, modalOpen, reloadUser, toggleModal]
	)
}

export type AuthContext = ReturnType<typeof useAuthContextRoot>

export const AuthContext = React.createContext<null | AuthContext>(null)

export function AuthContextProvider(props: { children: React.ReactNode }) {
	const value = useAuthContextRoot()
	return <AuthContext.Provider value={value}>{props.children}</AuthContext.Provider>
}

export function useAuth() {
	const context = React.useContext(AuthContext)

	if (!context) {
		throw new Error('Missing AuthContext')
	}

	return context
}

export function useIsSignedIn() {
	return useAuth().status === 'signedIn'
}
