import type { AppProps } from 'next/app'
import Head from 'next/head'
import { useEffect, useState } from 'react'
import TagManager from 'react-gtm-module'
import { QueryClient, QueryClientProvider } from 'react-query'
import { AuthContextProvider } from '../app/auth/UserContext'
import { Favicon } from '../app/components/Favicon'
import { SignupModal } from '../app/components/SignupModal'
import { getConfig } from '../app/next/config'
import '../app/styles/global.sass'
import { SearchContextProvider } from '../app/utils/SearchContext'
import { HydratingRoot } from '../libs/next/hydratation'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type AppPropsWithWorkaround = AppProps & { err: unknown; pageProps: any }

export default function App({ Component, pageProps, err }: AppPropsWithWorkaround) {
	// Something from Contember RichTextRenderer
	;(global as unknown as { __DEV_MODE__: boolean }).__DEV_MODE__ =
		process.env.NODE_ENV === 'development'

	useEffect(() => {
		document.documentElement.className = document.documentElement.className.replace(
			/\bno-js\b/g,
			'js'
		)
	}, [])

	const [queryClient] = useState(() => new QueryClient())

	useEffect(() => {
		window.didomiOnReady?.push(function (Didomi) {
			Didomi.getObservableOnUserConsentStatusForVendor('c:czechnews-nJYjk3Bf')
				.filter(function (status: boolean) {
					return status === true
				})
				.first()
				.subscribe(function () {
					const GTM_CODE = getConfig()?.publicRuntimeConfig.GTM_TRACKING_ID ?? ''
					TagManager.initialize({ gtmId: GTM_CODE })
					new GTMService()
				})
		})
	}, [])

	return (
		<HydratingRoot>
			<QueryClientProvider client={queryClient}>
				<AuthContextProvider>
					<SearchContextProvider>
						<Head>
							<meta
								name="viewport"
								content="initial-scale=1.0, width=device-width, viewport-fit=cover, maximum-scale=1"
							/>
							<link rel="apple-touch-icon" href="/apple-touch-icon.png" />
							<link rel="icon" href="/favicon.ico" sizes="any" />
							<link rel="icon" href="/favicon.svg" type="image/svg+xml" />
							<meta name="theme-color" content="#ffffff" />
							<meta name="color-scheme" content="only light" />
							<meta name="robots" content="max-image-preview:large" />
							<meta
								name="fomo-authorized"
								content={pageProps?.metaData?.info?.request?.authorized ? 'true' : 'false'}
							/>
						</Head>
						<Favicon />
						<SignupModal />
						<Component {...pageProps} err={err} />
					</SearchContextProvider>
				</AuthContextProvider>
			</QueryClientProvider>
		</HydratingRoot>
	)
}
