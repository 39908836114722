/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import type { LinkResult } from '../data/fragments/LinkFragment'
import style from './Button.module.sass'
import { Link } from './Link'

type LinkButtonProps = {
	link: LinkResult | string
	type: 'link'
	target?: string
	onClick?: React.MouseEventHandler<HTMLAnchorElement>
}

type SubmitButtonProps = {
	type: 'submit'
	onClick?: React.MouseEventHandler<HTMLButtonElement>
	name?: string
	value?: string
}

type ButtonButtonProps = {
	type: 'button'
	onClick?: React.MouseEventHandler<HTMLButtonElement>
	name?: string
	value?: string
}

export type ButtonProps = {
	children: React.ReactNode
	view?: 'dark' | 'light' | 'textual' | 'highlight' | 'green'
	shadow?: boolean
	large?: boolean
	dataAttributes?: {
		[name: string]: string
	}
} & (LinkButtonProps | SubmitButtonProps | ButtonButtonProps)

export function Button(props: ButtonProps) {
	const view = props.view ?? 'dark'
	const className = clsx(
		style.Button,
		style[`view_type_${view}`],
		props.shadow && style.shadow,
		props.large && style.large
	)
	switch (props.type) {
		case 'link':
			if (typeof props.link == 'string') {
				return (
					<Link
						className={className}
						href={props.link}
						target={props.target}
						onClick={props.onClick}
						dataAttributes={props.dataAttributes}>
						{props.children}
					</Link>
				)
			}
			return (
				<Link
					className={className}
					link={props.link}
					target={props.target}
					onClick={props.onClick}
					dataAttributes={props.dataAttributes}>
					{props.children}
				</Link>
			)
		case 'submit':
			return (
				<button
					onClick={props.onClick}
					name={props.name}
					value={props.value}
					type="submit"
					className={className}
					{...Object.fromEntries(
						Object.entries(props.dataAttributes ?? []).map(([name, value]) => [
							`data-${name}`,
							value,
						])
					)}>
					{props.children}
				</button>
			)
		case 'button':
			return (
				<button
					onClick={props.onClick}
					name={props.name}
					value={props.value}
					type="button"
					className={className}
					{...Object.fromEntries(
						Object.entries(props.dataAttributes ?? []).map(([name, value]) => [
							`data-${name}`,
							value,
						])
					)}>
					{props.children}
				</button>
			)
	}
}
