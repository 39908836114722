import clsx from 'clsx'
import * as React from 'react'
import { SignInForm } from '../auth/SignInForm'
import { SignUpform } from '../auth/SignUpForm'
import { useAuth } from '../auth/UserContext'
import type { ImageResult } from '../data/fragments/ImageFragment'
import { signUpImage } from '../images'
import { mockId } from '../mocks/mockId'
import { ContemberImage } from './ContemberImage'
import { Icon } from './Icon'
import style from './SignupModal.module.sass'

export function SignupModal(props: {
	headline?: string
	description?: string
	image?: ImageResult
}) {
	const auth = useAuth()

	const [signUp, setSignUp] = React.useState(false)

	React.useEffect(() => {
		setSignUp(false)
	}, [auth.modalOpen])

	return (
		<div className={clsx(style.wrapper, auth.modalOpen && style.open)}>
			<div className={style.inner}>
				<button className={style.close} onClick={auth.toggleModal}>
					<Icon name="cross" />
				</button>
				<div className={style.image}>
					<ContemberImage
						layout="fill"
						objectFit="cover"
						image={
							props.image
								? props.image
								: {
										id: mockId(),
										size: 1000,
										width: signUpImage.default.width,
										height: signUpImage.default.height,
										url: signUpImage.default.src,
								  }
						}
					/>
				</div>
				<div className={style.content}>
					<h2 className={style.title}>
						{props.headline ?? (signUp ? 'Registrace' : 'Přihlášení se k heyfomo')}
					</h2>
					<article className={style.description}>
						{props.description ??
							'Po přihlášení budeš mít přístup ke všemu obsahu na heyfomo.cz, můžeš komentovat články a další features, just do it!'}
					</article>
					{signUp ? (
						<SignUpform />
					) : (
						<SignInForm
							toggleRegisterFormCallback={() => {
								setSignUp(!signUp)
							}}
						/>
					)}
					{/* <button
						onClick={() => {
							setSignUp(!signUp)
						}}>
						{signUp ? 'Přihlásit se' : 'Registrace'}
					</button> */}
					<button
						onClick={async () => {
							auth.initSignInIDP()
						}}>
						Přihlásit se přes CNC
					</button>
				</div>
			</div>
		</div>
	)
}
