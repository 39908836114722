import { useMutation } from 'react-query'
import { SignUpInput } from './SignUpInput'
import { useAuth } from './UserContext'
import style from './SigninForm.module.sass'
import { Button } from '../components/Button'
import { Link } from '../components/Link'

export function SignUpform() {
	const auth = useAuth()

	const mutation = useMutation(async (formData: FormData) => {
		const data = Object.fromEntries([...formData.entries()])
		const validData = SignUpInput.parse(data)

		const signUp = await auth.signUp(validData)

		return signUp
	})

	return (
		<form
			action=""
			method="POST"
			onSubmit={async (e) => {
				e.preventDefault()
				mutation.mutate(new FormData(e.currentTarget))
			}}
			className={style.wrapper}>
			<fieldset className={style.fieldset} disabled={mutation.isLoading}>
				<input className={style.input} name="email" type="email" placeholder="Váš e-mail" />
				<input className={style.input} name="password" type="password" placeholder="Heslo" />
				<div className={style.actions}>
					<div className={style.checkbox}>
						<input id="signup-agree" type="checkbox" name="agree"></input>
						<label htmlFor="signup-agree">
							Souhlasím s <Link href="/">podmínkami</Link>
						</label>
					</div>
					<Link href="/reset-hesla">Reset hesla</Link>
				</div>
				<div>
					<Button view="highlight" type="submit">
						Vytvořit účet
					</Button>
				</div>
			</fieldset>
		</form>
	)
}
