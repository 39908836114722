import { LinkType } from '../../generated/content'
import type { LinkResult } from '../data/fragments/LinkFragment'

export function getUrlFromLinkResult(link: LinkResult) {
	switch (link.type) {
		case LinkType.external:
			return { href: link.externalLink ?? '' }
		case LinkType.internal:
			return { href: link.internalLink?.url ?? '' }
	}
	return { href: '#' }
}
